.btn {
    box-sizing: border-box;
    padding: 0 16px;
    margin: 0;
    border: none;
    font-size: 14px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease;
    background: #3E8CCA33;
    outline: none;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #CBDEF4;
    white-space: nowrap;

    &:hover:not(&:disabled),
    &:focus:not(&:disabled) {
        background: #3E8CCA1F;
    }

    &:disabled {
        cursor: default;
        color: #CBDEF433;
        background: #3E8CCA1F;
    }

    &.block {
        width: 100%;
    }
}

.s {
    font-size: 12px;
    padding: 0 14px;
    height: 32px;
}
