.root {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #3E8CCA33;
    color: #CBDEF4;
    box-shadow: none;
    transition: 0.1s ease;

    &.disabled {
        opacity: 0.4;
    }

    &.focus {
        box-shadow: 0 0 0 1px #CBDEF4;
    }

    &.invalid {
        box-shadow: 0 0 0 1px #FF3300;
    }

    &.withPrefix {
        .input {
            padding-left: 8px;
        }
    }
}

.input {
    flex-grow: 1;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 0 12px;
    border: none;
    width: 100%;
    height: 36px;
    font-size: 14px;
    outline: none;
    background: transparent;
    color: #CBDEF4;

    &::placeholder {
        opacity: 1;
        color: #CBDEF433;
    }
}

.postfix,
.prefix {
    gap: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
}

.postfix {
    padding-right: 12px;
}

.prefix {
    padding-left: 12px;
}
