.root {
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    overflow: auto;
    background: #3E8CCA33;
    color: #CBDEF4;
    word-break: break-all;
    white-space: pre-wrap;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #aaa;
    }

    code {
        word-break: break-all;
        line-height: 1.4;
    }
}
