.root {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1024px;
    margin: 0 auto;
    min-height: 100vh;
    padding: 32px;
    box-sizing: border-box;
    @media (max-width: 767px) {
        padding: 16px;
    }
}

.container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 32px;
    background: #051C2E;
    color: #fff;
    border-radius: 16px;
    padding: 32px;
    align-items: flex-start;
    box-shadow: 0 0 100px rgba(0,188,255,0.2), 0 0 20px rgba(0,188,255,0.05), 0 0 1px rgba(0,188,255,0.7);
    @media (max-width: 1023px) {
        grid-template-columns: minmax(0, 1fr);
    }
    @media (max-width: 767px) {
        padding: 15px;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title {
    margin: 0;
    font-size: 26px;
    font-weight: 500;
}

.form {
    display: flex;
    gap: 16px;
    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.input {
    flex-grow: 1;
}

.copy {
    align-self: flex-end;
}
